import React, { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import { Box, Button, Text } from 'theme-ui';
import Drawer from 'components/drawer';
import { DrawerContext } from 'contexts/drawer/drawer.context';
import { IoMdClose, IoMdMenu } from 'react-icons/io';
import { Link, NavLink } from '../link';
import menuItems from './header.data';
import Logo from 'components/logo';
import logo from 'assets/images/logo.png';


const MobileDrawer = () => {
  const { state, dispatch } = useContext(DrawerContext);
  const { language, templatesPage } = useContext(AppContext);
  const es = language == "es";

  const getStartedBtn = templatesPage ?
    <Link
      path={es ? "/es#pricing" : "/#pricing"}
      label={
        <Button variant="primary">
          { es ? "Comenzar" : "Get Started"}
        </Button>
      }
    />
  :
    <NavLink
      path="pricing"
      label={
        <Button variant="primary">
          { es ? "Comenzar" : "Get Started"}
        </Button>
      }
    />

  // Toggle drawer
  const toggleHandler = React.useCallback(() => {
    dispatch({
      type: 'TOGGLE',
    });
  }, [dispatch]);

  return (
    <Drawer
      width="320px"
      drawerHandler={
        <Box sx={styles.handler}>
          <IoMdMenu size="22px" />
        </Box>
      }
      open={state.isOpen}
      toggleHandler={toggleHandler}
      closeButton={<IoMdClose size="24px" color="#02073E" />}
      drawerStyle={styles.drawer}
      closeBtnStyle={styles.close}
    >
      <Box sx={styles.content}>
        <Logo image={logo}/>
        <Box sx={styles.menu}>
          { templatesPage ?
          <>
            <Link path={es ? "/es" : "/"}>
              {es ? "Inicio" : "Home"}
            </Link>
            <Link path={es ? "/es#pricing" : "/#pricing"}>
              {es ? "Precio" : "Pricing"}
            </Link>
          </>
          :  
          menuItems.map(({ path, label, labelEs }, i) => (
            <NavLink
              path={path}
              label={es ? labelEs || label : label}
              offset={-10}
              onClick={toggleHandler}
              key={i}
            />
          ))}
        </Box>

        <Box sx={styles.menuFooter}>
          <Link
            path="https://app.writelytic.com"
            label={
              <Button variant="secondary" sx={styles.loginbtn}>
                { es ? "Acceder" : "Login"}
              </Button>
            }
          />
          {getStartedBtn}
        </Box>
      </Box>
    </Drawer>
  );
};

const styles = {
  logo: {
    fontSize: '34px',
    lineHeight: '34px',
    color: "#000",
    fontWeight: "500",
  },
  handler: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: '0',
    width: '26px',

    '@media screen and (min-width: 960px)': {
      display: 'none',
    },
  },

  drawer: {
    width: '100%',
    height: '100%',
    background: '#fff',
  },

  close: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '30px',
    right: '30px',
    zIndex: '1',
  },

  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    pt: '30px',
    pb: '40px',
    px: '30px',
  },

  menu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',

    a: {
      fontSize: '16px',
      fontWeight: '400',
      color: 'black',
      py: '5px',
      cursor: 'pointer',
    },
  },

  menuFooter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mt: 'auto',
  },

  loginbtn: {
    mb: '5px'
  },
};

export default MobileDrawer;
