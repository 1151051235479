import { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
/** @jsx jsx */
import { jsx, Image } from 'theme-ui';
import { Link } from 'components/link';

export default function Logo({ image }) {
  const { language } = useContext(AppContext);
  const es = language == "es";

  return (
    <Link
      path={es ? "/es" : "/"}
      sx={{
        variant: 'links.logo',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Image src={image} alt="logo" />
    </Link>
  );
}
