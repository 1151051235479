import React, { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
/** @jsx jsx */
import { jsx, Box, Button, Container, Flex, Text } from 'theme-ui';
import { Link, NavLink } from 'components/link';
import Logo from 'components/logo';
import { DrawerProvider } from 'contexts/drawer/drawer.provider';
import LanguageSelector from '../language-selector';
import MobileDrawer from './mobileDrawer';
import menuItems from './header.data';
import logo from 'assets/images/logo.png';

export default function Header({ className }) {
  const { language, templatesPage } = useContext(AppContext);
  const es = language == "es";

  const getStartedBtn = templatesPage ?
    <Link
      path={es ? "/es#pricing" : "/#pricing"}
      label={
        <Button variant="primary">
          { es ? "Comenzar" : "Get Started"}
        </Button>
      }
    />
  :
    <NavLink
      path="pricing"
      label={
        <Button variant="primary">
          { es ? "Comenzar" : "Get Started"}
        </Button>
      }
    />

  return (
    <DrawerProvider>
      <header sx={styles.header} className={className}>
        <Container sx={styles.container}>
          <Logo image={logo}/>

          <Flex as="nav" sx={styles.nav}>
            { templatesPage ?
            <>
              <Link path={es ? "/es" : "/"} sx={styles.nav.navLink}>
                {es ? "Inicio" : "Home"}
              </Link>
              <Link path={es ? "/es#pricing" : "/#pricing"} sx={styles.nav.navLink}>
                {es ? "Precio" : "Pricing"}
              </Link>
            </>
            :
            menuItems.map(({ path, label, labelEs }, i) => (
              <NavLink
                sx={styles.nav.navLink}
                path={path}
                label={es ? labelEs || label : label}
                offset={-20}
                key={i}
              />
            ))}
          </Flex>

          <Box sx={styles.headerLangCont}>
            <LanguageSelector/>
          </Box>
          <Box sx={styles.headerBtnCont}>
            <Link
              path="https://app.writelytic.com"
              label={
                <Button variant="secondary" sx={styles.headerLoginBtn}>
                  { es ? "Acceder" : "Login"}
                </Button>
              }
            />
            {getStartedBtn}
          </Box>

          <MobileDrawer />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const styles = {
  headerLangCont: {
    ml: 'auto',
  },
  headerBtnCont: {
    display: ['none', null, null, "inline-block", 'inline-block'],
    mr: ['0', null, null, '20px', '0'],
  },
  headerLoginBtn: {
    mr: '5px',
  },
  header: {
    color: 'text_white',
    fontWeight: 'normal',
    py: '25px',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
    transition: 'all 0.4s ease',

    '&.sticky': {
      backgroundColor: 'background',
      color: 'text',
      py: '15px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: [null, null, null, null, null, null, '1390px'],
    '@media screen and (max-width: 960px)': {
      justifyContent: 'space-between',
    },
  },
  nav: {
    mx: 'auto',
    '@media screen and (max-width: 960px)': {
      display: 'none',
    },
    navLink: {
      fontSize: '16px',
      color: '#02073E',
      fontWeight: '400',
      cursor: 'pointer',
      lineHeight: '1.2',
      mr: '1rem',
      transition: '500ms',
      ':last-child': {
        mr: '0',
      },
      /*'&:hover, &.active': {
        color: 'primary',
      },*/
      '&:hover': {
        color: 'primary',
      },
    },
  },
};
