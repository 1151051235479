export default [
  {
    path: 'chat',
    label: 'Chat',
    labelEs: 'Chat',
  },
  {
    path: 'brandvoice',
    label: 'Brand voice',
    labelEs: 'Voz de marca',
  },
  /*{
    path: 'howitworks',
    label: 'How it works',
    labelEs: 'Cómo funciona',
  },*/
  {
    path: 'templates',
    label: 'Tools',
    labelEs: 'Herramientas',
  },
  {
    path: 'pricing',
    label: 'Pricing',
    labelEs: 'Precio',
  },
];
