import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../contexts/app-context';
import { Box } from 'theme-ui';
import { Link } from 'components/link';
import { HiTranslate } from "react-icons/hi";

export default function LanguageSelector() {
  const btnRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { language, path } = useContext(AppContext);
  const es = language == "es";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnRef.current && !btnRef.current.contains(event.target))
        setOpen(false);
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setOpen]);

  return (
    <Box sx={styles.container}>
      <Box as="button" sx={styles.btn} onClick={() => setOpen(!open)} ref={btnRef}>
        <HiTranslate/><span> ({es ? "Es" : "En"})</span>
      </Box>
      <Box className={open ? "open": ""} sx={styles.dropdown}>
        {es ?
        <>
          <Link to={path.replace(/(^\/es\/)|(^\/es$)/, "/")}>
            Inglés&nbsp;(English)
          </Link>
          <div>Español</div>
        </>
        :
        <>
          <div>English</div>
          <Link to={`/es${path || ""}`}>
            Spanish&nbsp;(Español)
          </Link>
        </>
        }
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    position: "relative",
  },
  btn: {
    fontSize: "1.2rem",
    cursor: "pointer",
    px: 0,
    mr: "10px",
    backgroundColor: "transparent",
    border: "none",
    "> svg": {
      color: "primary",
    },
    "> *": {
      display: "inline-block",
      verticalAlign: "middle",
    },
  },
  dropdown: {
    position: "absolute",
    right: 0,
    backgroundColor: "#fff",
    minWidth: "100%",
    maxHeight: "200px",
    overflow: "auto",
    py: "0.5rem",
    boxShadow: "0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)",
    display: "none",
    "&.open": {
      display: "block",
    },
    "> *": {
      display: "block",
      px: "0.5rem",
      color: "text",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,.04);",
      },
    }
  },
};
