import React, { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import { Box, Text, Container } from 'theme-ui';
import { Link } from 'components/link';
import Logo from 'components/logo';
import logo from 'assets/images/logo-light.png';

export default function Footer() {
  const { language, digistore24 } = useContext(AppContext);
  const es = language == "es";

  return (
    <Box as="footer" sx={styles.footer}>
      <Container sx={styles.container}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: ['column', null, null, null, null, 'row'],
          }}
        >
          <Logo image={logo}/>
          <Text
            as="p"
            sx={{
              color: '#ffffff',
              opacity: '0.7',
              fontSize: '14px',
              mt: ['10px', null, null, null, null, '0'],
              ml: "0.5rem",
            }}
          >
            Copyright {new Date().getFullYear()} {es ? "por" : "by"} Writelytic
          </Text>
        </Box>
        <Box sx={styles.linksWrap}>
          <Link path="https://digistore24.com/signup/497626,498181,508017" rel="nofollow">
            {es ? "Afiliados" : "Affiliates"}
          </Link>
          {digistore24 &&
          <a href={`/${es ? "es/" : ""}privacy-policy`} target="_blank" style={{textDecoration:"none"}}>
            {es ? "Política de privacidad" : "Privacy Policy"}
          </a>
          }
        </Box>
      </Container>
    </Box>
  );
}

const styles = {
  footer: {
    py: ['40px', null, null, null, null, '30px', '40px'],
    backgroundColor: '#020718',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#ffffff',
      opacity: '0.05',
    },
  },
  container: {
    display: 'flex',
    flexDirection: ['column', null, null, null, null, 'row'],
    justifyContent: ['center', null, null, null, null, 'space-between'],
    alignItems: 'center',
    position: 'relative',
    flexWrap: 'wrap',
  },
  linksWrap: {
    mt: ['15px', null, null, null, null, '0'],
    display: 'flex',
    flexWrap: 'wrap',
    a: {
      fontSize: ['14px', null, null, null, '16px'],
      color: '#ffffff',
      transition: 'all 500ms ease',
      '&:hover': { opacity: 0.7 },
    },
    'a+a': { ml: ['15px', null, null, null, '35px'] },
  },
};
